<template>
    <div id="app_div">
          <van-overlay :show="load_show" >
             <div style="position: absolute;top: 45%;left: 44%;">
                <van-loading size="24px"  color="white" vertical>加载中...</van-loading>
             </div>
        </van-overlay>
        <div class="label_box">
        </div>
        <div class="btn_box">
        </div>
         <div class="register_div" v-show="show">
            <div class="tabs_div">
            </div>
            <van-tabs v-model="active">
                <van-tab title="账号密码登录">
                    <div class="tab_body">
                        <van-cell-group>
                            <van-field
                                v-model="userNameLogin.loginName"
                                error
                                required
                                label="用户名"
                                placeholder="请输入用户名"
                            />
                            <van-field
                                v-model="userNameLogin.password"
                                required
                                type="password"
                                label="密码"
                                placeholder="请输入密码"
                            />
                        </van-cell-group>
                    </div>
                </van-tab>
            </van-tabs>
           
            <div class="tab_footer">
                <div style="height:30px;"></div>
                <div style="padding:0px 10px;">
                    <van-button type="primary" @click="userLogin" class="login_btn" block>验证登录</van-button>
                </div>
                <div style="height:20px;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {userNameLogin,} from '../../api/login'
import {loginUrlSotre,tokenStore} from '../../store/token'
import { Overlay,Loading,Button,Tab, Tabs,Field,CellGroup } from 'vant';
export default ({
    name:'mAgentHome',
    components:{
        [Overlay.name]:Overlay,
        [Loading.name]:Loading,
        [Button.name]:Button,
        [Tab.name]:Tab,
        [Tabs.name]:Tabs,
        [Field.name]:Field,
        [CellGroup.name]:CellGroup,
    },
    data(){
        return {
            active:0,
            load_show:false,
            openId:'',
            show:true,
            code_interval:undefined,
            code_second:60,
            code_btn:false,
            register_btn_class:false,
            userNameLogin:{
                loginName:'',
                password:'',
            }
        }
    },
    methods:{
        userLogin(){
            this.$toast.loading({duration: 0,forbidClick: true,message: '登录中',});               
            if(!this.userNameLogin.loginName || !this.userNameLogin.password){
                this.$toast.clear();
                this.$toast.fail('请输入账号和密码');
                return
            }
            userNameLogin(this.userNameLogin)
            .then(res=>{
                this.$toast.clear();
                this.$toast({type:res.status?'success':'fail',message:res.message})
                if(res.status){
                    var jsonObj = res.data;
                    tokenStore.set('Bearer '+res.data.token);
                    delete jsonObj.token
                    this.$router.push('/scrapPieces/index')
                }
            })
        }
    },    
    mounted(){       

    },
    created(){
        loginUrlSotre.set('/scrapPieces/login');
        // var token = tokenStore.get();
        // if(token){
        //     this.$router.push('/scrapPieces/index')
        // }
    }
})
</script>
<style scoped>
@import "./login.css";
</style>